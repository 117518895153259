import React from 'react';
import useSetTitle from "../../hooks/useSetTitle";

const Settings = () => {
    useSetTitle('Настройки аккаунта')

    return (
        <div>
        </div>
    );
};

export default Settings;