import { PDFDocument, rgb } from "pdf-lib";
import { User } from '../../../types/types';
import fontkit from "@pdf-lib/fontkit";

async function genDocument6(values: any, info: User) {
  const url = '/nimbus-roman.otf'
  const nimbusRomanBytes = await fetch(url).then((res) => res.arrayBuffer());

  const pdfDoc = await PDFDocument.create()
  pdfDoc.registerFontkit(fontkit)
  const nimbusRomanFont = await pdfDoc.embedFont(nimbusRomanBytes)

  const page = pdfDoc.addPage()
  const page2 = pdfDoc.addPage()
  const { width, height } = page.getSize()
  const fontSize = 14
  const textAdrees =`Ректору ГГНТУ
проф. Минцаеву М. Ш.`

  const text = `
    ${values?.regdate ?? '-'}                                                                     Рег. номер: ${values?.regnum ?? '-'}
                                                   
    
                                                          Заявление
    
    Прошу Вас дать указание провести закупку на ${values?.vidZakupki ?? '-'}
    1) Наименование и цель закупки: ${values?.nameZakupki ?? '-'}
    2) Источник финансирования: ${values?.istochnikFinansirovaniya ?? '-'}
    3) Начальная максимальная цена: ${values?.maxPriceRub ?? '-'} рублей, ${values?.maxPriceKopeck ?? '-'} копеек.
    4) Требования к работе: В соответствии с Техническим заданием (Приложение 1)
    5) Форма закупки: ${values?.formaZakupki ?? '-'} 
    \n\n\n\n\n\n
         
    \n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n
    Дата : ${values?.regdate}
    Исполнитель: ${info.lastname ?? ''} ${info.firstname ?? ''} ${info.middlename ?? ''}
    Отдел: ${info.department ?? '-'}
    Номер телефона: ${values?.phone ?? '-'}
  `
  const dateSroki = Array.isArray(values?.tehSroki) ? 
  `\n${values?.tehSroki?.[0]?.format('YYYY/MM/DD')} - ${values?.tehSroki?.[1]?.format('YYYY/MM/DD')}`
  : '';



  const text2 = `
                                                                                                                              Приложение 1
                                                                 Техническое задание 
    
    1) Наименование и цель закупки: ${values?.tehNameZakupki ?? 'Не предъявляется'}
    2) Перечень и объемы ТРУ, цена:
${(Array.isArray(values?.tru) && !!values?.tru?.length) ?
        values?.tru?.reduce((acc: string, item: any,index: number)=> acc + `         №${+index+1}\n
         Наименование ТРУ: ${item?.name}\n
         Количество: ${item?.count}\n
         Цена за единицу (руб.): ${item?.price}\n
         Сумма – (руб.): ${+item?.price * +item?.count}\n`, '')
        : '       Не предъявляется'}
    3) Место (поставки товара/оказания услуг/выполнения работ): ${values?.tehMesto ?? 'Не предъявляется'}
    4) Сроки (периоды) поставки товара/оказания услуг/выполнения работ: ${dateSroki ?? 'Не предъявляется'}
    5) Порядок оплаты: ${values?.tehPoryadokOplati ?? 'Не предъявляется'}
    6) Иные требования к работам и условиям их выполнения по усмотрению заказчика : ${values?.tehInieTrebovaniya ?? 'Не предъявляется'}
   
    
        
  `
  page.drawText(textAdrees, {
    x: width - 200,
    maxWidth: 180,
    y: height - height / 12,
    size: fontSize,
    font: nimbusRomanFont,
    color: rgb(0, 0, 0),
  })

  page2.drawText(text2, {
    x: 50,
    maxWidth: width - 50,
    y: height - height / 12,
    size: 12,
    font: nimbusRomanFont,
    color: rgb(0, 0, 0),
  })


  page.drawText(text, {
    x: 50,
    maxWidth: width - 50,
    y: height - height / 12 - 150,
    size: fontSize,
    font: nimbusRomanFont,
    color: rgb(0, 0, 0),
  })

  

  const pdfBytes = await pdfDoc.save()
  
  return pdfBytes
}

export default genDocument6;