export const LOGIN_PAGE_ROUTE = '/auth'


export const MAIN_PAGE_ROUTE = '/user'
    export const HOME_ROUTE = 'home'
    export const SETTINGS_PROFILE_ROUTE = 'settings'
    export const CREATE_DOCUMENT_ROUTE = 'create'
    export const TEMPLATES_CREATE_DOCUMENT_ROUTE = 'templates'
    export const META_DOCUMENT_LIST_ROUTE = 'list'
    export const META_DOCUMENT_INFO_ROUTE = 'documentinfo'

