import { 
  Form, 
  Input, 
  Button, 
  Select,
  Typography,
  Result,
  InputNumber,
  DatePicker
} from "antd";

import { useState } from "react";
import { useForm } from "antd/lib/form/Form";
import { signularisApi, useCreateMetaDocumentMutation, useGetRecipientsListQuery } from "../../../store/api/signularisApi";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { useAppDispatch } from "../../../hooks/redux";

import genDocument6 from '../misc/gendoc6';
import {CREATE_DOCUMENT_ROUTE, MAIN_PAGE_ROUTE} from "../../../routing/consts";
import {Link} from "react-router-dom";
import {DocTemplateType} from "../../../types/types";
import { downloadPdfFileResponse } from "../../../utils/download-pdf";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const { RangePicker } = DatePicker;
const { Title } = Typography;
const { Item, List } = Form;
const { Option } = Select;



const vidZakupki = [
  'приобретение товара',
  'оказание услуг',
  'выполнение работ'
]

const istochnikFinansirovaniya = [
  'Бюджетные средства',
  'Внебюджетные средства',
]

const poryadokOplati = [
  'поэтапная оплата',
  'предоплата до 30%',
  'постоплата'
]

//generates random id;
let guid = () => {
  let s4 = () => {
      return Math.floor((1 + Math.random()) * 0x100)
          .toString(10)
          .substring(1);
  }
  //return id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
  return s4() + s4() + s4() + s4() + s4();
}

function styledDate() {
  const months = ["января", "февраля", "марта", "апреля", "мая", "июня", "июля", 
                  "августа", "сентября", "октября", "ноября", "декабря"]
  const dobj = new Date()
  return `${dobj.getDate()} ${months[dobj.getMonth()]} ${dobj.getFullYear()}`
}





function Template() {
  const info = useSelector((state: RootState) => state.userinfo)
  const dispatch = useAppDispatch()
  const { data: recipients } = useGetRecipientsListQuery()

  const defaulRepicients = [ "6250271072944c7123ebdd53", "6639d5d8231c0c852d8b0e94", "6639d522231c0c852d8b0e93" ]
  const recipientsList = recipients?.filter(item => !defaulRepicients.includes(item.id)) || []

 
  const [docname, setDocname] = useState("")
  const docTemplate : DocTemplateType = 'purchaseOfService'

  const [createMetaDocument, {
    isLoading: mutationLoading, 
    isSuccess: mutationSuccess, 
    isError: mutationError,
  } ] = useCreateMetaDocumentMutation()

  const [form] = useForm()

  const onFinish = async (values: any) => {
    // user info
    const regnum = guid()

    setDocname('Заявка на приобретение товара/оказание услуг/выполнение работ')
    const formData = new FormData()
    formData.append("title", `Заявка на приобретение товара/оказание услуг/выполнение работ №${regnum}`)
    formData.append("brief", "Сгенерировано из шаблона")
    formData.append("recipients", values.recipients.join(","))
    formData.append("documentType", docTemplate)

    values.regnum = regnum
    
    values.regdate = styledDate()
    values.dutyface = `${info.lastname} ${info.firstname} ${info.middlename}`
    const pdfBytes = await genDocument6(values, info)
    
    formData.append("file", new Blob([pdfBytes]))
    // downloadPdfFileResponse(new Blob([pdfBytes]))
    createMetaDocument(formData)
    dispatch(signularisApi.util.invalidateTags(['MetaDocument']))
  }

  if (mutationSuccess) {
    return (
      <Result
        status="success"
        title="Документ создан"
        subTitle={`Ваш документ ${docname} успешно создан.`}
        extra={[
          <Link to={MAIN_PAGE_ROUTE + '/' + CREATE_DOCUMENT_ROUTE}><Button >Создать еще</Button></Link>
        ]}
      />
    )
  }

  if (mutationError) {
    return (
      <Result
        status="error"
        title="Не удалось создать документ"
        extra={[
          <Link to={MAIN_PAGE_ROUTE + '/' + CREATE_DOCUMENT_ROUTE}><Button >Создать еще</Button></Link>
        ]}
      />
    )
  }

  return (
    <>
      <Title level={2} style={{ marginBottom: '30px' }}>Заявка на приобретение товара/оказание услуг/выполнение работ</Title>
      <p style={{color: 'red', marginBottom: '30px', fontWeight: 'normal', fontSize: 14}}>
      При создании заявки на приобретение товара/оказание услуг/выполнение работ, необходимо в получателях указать КУРИРУЮЩЕГО ПРОРЕКТОРА.</p>
      <Form  form={form} onFinish={onFinish} initialValues={{regtext: "Прошу Вас считать меня вышедшей (им) на работу на условиях неполного рабочего времени ( 7 час/д или 0,5 ставки) с сохранением пособия по уходу за ребенком до достижения им возраста 1,5 лет с"}}>
        <Item style={{marginBottom: 0}}  name="recipients" rules={[{ required: true, message: 'Обязательное поле', }]}>
          <Select
            mode="multiple"
            maxTagCount='responsive'
            allowClear
            placeholder="Получатели"   
            notFoundContent={"Ничего не найдено"}
            filterOption={(input, option) =>
              option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              option?.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            { 
              recipientsList?.map((recipient) => (
                <Option key={recipient.id} value={recipient.id} label={recipient.fullname}>
                  {recipient.fullname} {!!recipient.position && <>- <span style={{color: 'gray'}}>{recipient.position}</span></>}
                </Option>
              ))
            }
          </Select>
        </Item>
        <span style={{marginTop: 5, marginBottom: 25, display: 'block'}}>
          <span style={{
                    display: 'inline-block',
                    backgroundColor: '#f5f5f5',
                    borderRadius: 5,
                    marginRight: 5,
                    padding: '2px 7px'
                }}>Минцаев Магомед Шавалович
          </span>
          <span style={{
              display: 'inline-block',
              backgroundColor: '#f5f5f5',
              borderRadius: 5,
              marginRight: 5,
              padding: '2px 7px'
          }}>Гуржиханов Аслан Юсуп-Хаджиевич
          </span>
          <span style={{
              display: 'inline-block',
              backgroundColor: '#f5f5f5',
              borderRadius: 5,
              padding: '2px 7px'
          }}>Батаева Маднат Карим-Султановна
          </span>
        </span>

        <Item name="vidZakupki" rules={[{ required: true, message: 'Обязательное поле' }]}>
          <Select
            placeholder="Вид закупки"   
            notFoundContent={"Ничего не найдено"}
            options={vidZakupki.map((e)=>({value: e, label: e}))}
          />
        </Item>
        <Item className='my-input' name="nameZakupki" rules={[{ required: true, message: 'Обязательное поле' }]}>
          <Input placeholder="Наименование и цель закупки"/>
        </Item>
        <Item name="istochnikFinansirovaniya" rules={[{ required: true, message: 'Обязательное поле' }]}>
          <Select
            placeholder="Источник финансирования"   
            notFoundContent={"Ничего не найдено"}
            options={istochnikFinansirovaniya.map((e)=>({value: e, label: e}))}
          />
        </Item>
        <div>Начальная максимальная цена</div>
        <div style={{display: 'flex', gap: 10,}}>
          <Item tooltip="Руб" style={{width: '10rem '}}  className='my-input' name="maxPriceRub" rules={[{ required: true, message: 'Обязательное поле' }]}>
            <InputNumber 
              style={{width: '10rem'}}
              placeholder="рублей"
            />
          </Item>
          <Item className='my-input' name="maxPriceKopeck" rules={[{ required: true, message: 'Обязательное поле' }]}>
            <InputNumber  placeholder="копеек"/>
          </Item>
        </div>
        <Item className='my-input' name="formaZakupki">
          <Input placeholder="Форма закупки"/>
        </Item>
        <Item className='my-input' name="phone">
          <InputNumber style={{ width: '100%'}}  placeholder="Номер телефона"/>
        </Item>
        <p style={{fontSize: '1.125rem', lineHeight: '1.75rem'}}>Требования к работе:</p>
        <p style={{textAlign: 'center', marginBottom: '1rem' }} >Техническое задание:</p>

        <Item className='my-input' name="tehNameZakupki" rules={[{ required: true, message: 'Обязательное поле' }]}>
          <Input placeholder="Наименование и цель закупки"/>
        </Item>
        <div style={{border: "1px solid black", borderRadius: '10px', padding: '1rem', marginBottom: '1rem'}} className="p-4 mb-4">
        <Form.List  name="tru">
        {(fields, { add, remove }) => (
          <>
            <div style={{display: 'flex',gap: '1rem',alignItems: 'center'}} >
              <p className="">Перечень и объемы ТРУ, цена</p>
              <Form.Item style={{marginBottom: '0px '}} >
                <Button style={{display: 'flex ',alignItems: 'center ',marginBottom: '0px '}}  type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  Добавить
                </Button>
              </Form.Item>
            </div>
            
            {fields.map(({ key, name, ...restField },index) => (
                <>
                <p style={{marginBottom: '0.5rem'}} >{index + 1}) <MinusCircleOutlined   style={{color: 'rgb(185 28 28 / 1) '}} onClick={() => remove(name)} /></p>
                <Item
                className='my-input'
                  {...restField}
                  name={[name, 'name']}
                  rules={[{ required: true, message: 'Обязательное поле' }]}
                >
                  <Input placeholder="Наименование ТРУ" />
                </Item>
                <Item
                className='my-input'
                  {...restField}
                  name={[name, 'count']}
                  rules={[{ required: true, message: 'Обязательное поле' }]}
                >
                  <InputNumber style={{ width: '100%'}}  placeholder="Количество"/>
                </Item>
                <Item className='my-input' name={[name, 'price']} {...restField} rules={[{ required: true, message: 'Обязательное поле' }]}>
                  <InputNumber style={{ width: '100%'}}  placeholder="Цена за единицу, руб."/>
                </Item>
                </>
            ))}
            
          </>
        )}
      </Form.List></div>
        <Item className='my-input' name="tehMesto" >
          <Input placeholder="Место (поставки товара/оказания услуг/выполнения работ)"/>
        </Item>
        <div>Сроки (периоды) поставки товара/оказания услуг/выполнения работ</div>
        <Item name="tehSroki">
          <RangePicker placeholder={['Начало','Конец']} />
        </Item>
        <Item name="tehPoryadokOplati">
          <Select
            placeholder="Порядок оплаты"   
            notFoundContent={"Ничего не найдено"}
            options={poryadokOplati.map((e)=>({value: e, label: e}))}
          />
        </Item>
        <Item className='my-input' name="tehInieTrebovaniya" >
          <Input placeholder="Иные требования к работам и условиям их выполнения по усмотрению заказчика (для включения в договор)"/>
        </Item>

        <Button className='my-btn margin-bottom-xl text-black'  type="primary"  htmlType="submit" loading={mutationLoading}>Создать документ</Button>
      </Form>
    </>
  )
}

export default Template;