import {Link, Navigate, Route, Routes} from "react-router-dom";
import { authPageRoutes, publicPageRoutes } from "../../routing/routes";
import {Button, Result } from "antd";
import {FrownOutlined} from "@ant-design/icons";


const PageRouter = () => {
    const token = localStorage.getItem('token');
    // const token = true

    return (
        <>
            <Routes>
                {!token && publicPageRoutes.map(({ path, Component }) =>
                    <Route key={path} path={path} element={Component}  />
                )}
                {token && authPageRoutes.map(({ path, Component , Screens}) =>
                    <Route key={path} path={path} element={Component}  >
                        {
                            Screens && Screens.length > 0 && <>
                            {Screens.map(({ path, Component }) =>
                                (
                                    <Route key={path} path={path} element={Component}  />
                                )
                            )}
                            <Route  path={"*"} element={Screens[0].Component}  />
                            </>
                        }
                    </Route>
                )}
                <Route path='/' element={<Navigate to={token ? authPageRoutes[0].path : publicPageRoutes[0].path} />} />
                <Route path='*' element={<Result
                    icon={<FrownOutlined />}
                    title="404"
                    subTitle="Извините, запрашиваемая страница не найдена."
                    extra={<Link to={'/'} ><Button style={{maxWidth: 300}} className='my-btn' type="primary">Вернуться на главную</Button></Link>}
                />} />
            </Routes>
        </>
    );
};

export default PageRouter;

