// int this file all request mades axios
import {PDFDocument, rgb} from 'pdf-lib';
import {message} from "antd";
import axios from "axios";
import fontkit from "@pdf-lib/fontkit";
import {User} from "../../types/types";

const signRequest = async (
    id: string,
    password: string,
    fileId: string,
    info: User,
    signLen: number,
    comment?: string) => {
    return axios.get(`/api/v1/getpdf/${fileId}`, {
        responseType: 'arraybuffer',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
            'Accept': 'application/pdf'
        }
    })
        .then(async (response) => {
            const url = '/nimbus-roman.otf'
            const nimbusRomanBytes = await fetch(url).then((res) => res.arrayBuffer());

            const pdfDoc = await PDFDocument.load(response.data)
            pdfDoc.registerFontkit(fontkit)
            const nimbusRomanFont = await pdfDoc.embedFont(nimbusRomanBytes)
            const pages = pdfDoc.getPages()
            const firstPage = pages[0]
            const { width: pageWitdh} = firstPage.getSize()
            let now = new Date().toLocaleString();
            const text1 = `Документ подписан простой электронной подписью`
            const text2 = `ФИО: ${info.lastname} ${info.firstname} ${info.middlename}`
            const text3 = `Должность: ${info.position}`
            const text4 = `Дата подписания: ${now}`
            const textSize = 10
            // @ts-ignore
            const a = [text1,text2,text3,text4].reduce((acc, n) => (acc[n.length] = n, acc), []).pop().length
            const textWidth = nimbusRomanFont.widthOfTextAtSize('A'.repeat(a/2)+'а'.repeat(a/2), textSize)
                // @ts-ignore
                // nimbusRomanFont.widthOfTextAtSize([text1,text2,text3,text4].reduce((acc, n) => (acc[n.length] = n, acc), []).pop(), textSize)
            const textHeight = nimbusRomanFont.heightAtSize(textSize)
            // @ts-ignore

            firstPage.drawText(text1, {
                x: 20,
                y: 70 + (signLen * 75),
                size: textSize,
                lineHeight: 10,
                font: nimbusRomanFont,
                color: rgb(0.1, 0.1, 0.9),
                opacity: 0.8,
            })
            firstPage.drawText(text2, {
                x: 20,
                y: 60 + (signLen * 75),
                size: textSize,
                lineHeight: 10,
                font: nimbusRomanFont,
                color: rgb(0.1, 0.1, 0.9),
                opacity: 0.8,
            })
            firstPage.drawText(text3, {
                x: 20,
                y: 50 + (signLen * 75),
                size: textSize,
                lineHeight: 10,
                font: nimbusRomanFont,
                color: rgb(0.1, 0.1, 0.9),
                opacity: 0.8,
            })
            firstPage.drawText(text4, {
                x: 20,
                y: 40 + (signLen  * 75),
                size: textSize,
                lineHeight: 10,
                font: nimbusRomanFont,
                color: rgb(0.1, 0.1, 0.9),
                opacity: 0.8,
            })
            firstPage.drawRectangle({
                x: 17,
                y: 37 + (signLen * 75),
                width: textWidth + 6,
                height: textHeight * 4,
                borderColor: rgb(0.1, 0.1, 0.9),
                borderWidth: 1.5,
            })

            if (comment) {
                firstPage.drawText(`*${comment}`, {
                    x: 20,
                    y: 28 + (signLen * 75),
                    size: 8,
                    opacity: 0.7,
                    lineHeight: 8,
                    font: nimbusRomanFont,
                    maxWidth: pageWitdh - 40,
                    color: rgb(0.6, 0.1, 0.1),
                })
            }
            return await pdfDoc.save()
        })
        .then((pdfEdited) => {
            const formData = new FormData()
            formData.append("file", new Blob([pdfEdited]))
            formData.append("password", password)
            formData.append("documentId", id)
            return axios.post(`/api/v1/signwithupdate`,
                formData,
                {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json',
                        'Accept': 'application/pdf'
                    },

                })
        })
        .then((response) => {
            response.status >= 200 && response.status <= 299 ?
                message.success("Документ подписан") : message.error("Не удалось подписать документ")
        })
        .catch((err) => {
            console.log(err)
            message.error("Не удалось подписать документ")
        })
}

const declineRequest = async (
    id: string,
    password: string,
    fileId: string,
    info: User,
    signLen: number,
    comment?: string) => {

    if(!comment?.trim().length){
        return declineWitchOutCommentRequest(id,password)
    }
    return axios.get(`/api/v1/getpdf/${fileId}`, {
        responseType: 'arraybuffer',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
            'Accept': 'application/pdf'
        }
    })
        .then(async (response) => {
            const url = '/nimbus-roman.otf'
            const nimbusRomanBytes = await fetch(url).then((res) => res.arrayBuffer());

            const pdfDoc = await PDFDocument.load(response.data)
            pdfDoc.registerFontkit(fontkit)
            const nimbusRomanFont = await pdfDoc.embedFont(nimbusRomanBytes)
            const pages = pdfDoc.getPages()
            const firstPage = pages[0]
            const { width: pageWitdh} = firstPage.getSize()
            let now = new Date().toLocaleString().slice(0,10);
            const text1 = `Документ отклонён`
            const text2 = `ФИО: ${info.lastname} ${info.firstname} ${info.middlename}`
            const text3 = `Должность: ${info.position}`
            const text4 = `Дата отклонения: ${now}`
            const textSize = 10
            // @ts-ignore
            const a = [text1,text2,text3,text4].reduce((acc, n) => (acc[n.length] = n, acc), []).pop().length
            const textWidth = nimbusRomanFont.widthOfTextAtSize('A'.repeat(a/2)+'а'.repeat(a/2), textSize)
            // @ts-ignore
            // nimbusRomanFont.widthOfTextAtSize([text1,text2,text3,text4].reduce((acc, n) => (acc[n.length] = n, acc), []).pop(), textSize)
            // const textWidth =
            //     // @ts-ignore
            //     nimbusRomanFont.widthOfTextAtSize([text1,text2,text3,text4].reduce((acc, n) => (acc[n.length] = n, acc), []).pop(), textSize)
            const textHeight = nimbusRomanFont.heightAtSize(textSize)

            firstPage.drawText(text1, {
                x: 20,
                y: 70 + (signLen * 75),
                size: textSize,
                lineHeight: 10,
                font: nimbusRomanFont,
                color: rgb(1, 0, 0),
                opacity: 0.8,
            })
            firstPage.drawText(text2, {
                x: 20,
                y: 60 + (signLen * 75),
                size: textSize,
                lineHeight: 10,
                font: nimbusRomanFont,
                color: rgb(1, 0, 0),
                opacity: 0.8,
            })
            firstPage.drawText(text3, {
                x: 20,
                y: 50 + (signLen * 75),
                size: textSize,
                lineHeight: 10,
                font: nimbusRomanFont,
                color: rgb(1, 0, 0),
                opacity: 0.8,
            })
            firstPage.drawText(text4, {
                x: 20,
                y: 40 + (signLen  * 75),
                size: textSize,
                lineHeight: 10,
                font: nimbusRomanFont,
                color: rgb(1, 0, 0),
                opacity: 0.8,
            })
            firstPage.drawRectangle({
                x: 17,
                y: 37 + (signLen * 75),
                width: textWidth + 6,
                height: textHeight * 4,
                borderColor: rgb(1, 0, 0),
                borderWidth: 1.5,
            })

            if (comment) {
                firstPage.drawText(`*${comment}`, {
                    x: 20,
                    y: 28 + (signLen * 75),
                    size: 8,
                    opacity: 0.7,
                    lineHeight: 8,
                    font: nimbusRomanFont,
                    maxWidth: pageWitdh - 40,
                    color: rgb(0.6, 0.1, 0.1),
                })
            }
            return await pdfDoc.save()
        })
        .then((pdfEdited) => {
            const formData = new FormData()
            formData.append("file", new Blob([pdfEdited]))
            formData.append("password", password)
            formData.append("documentId", id)
            return axios.post(`/api/v1/declinewithupdate`,
                formData,
                {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json',
                        'Accept': 'application/pdf'
                    },

                })
        })
        .then((response) => {
            response.status >= 200 && response.status <= 299 ?
                message.success("Документ отклонен") : message.error("Не удалось отклонить документ")
        })
        .catch((err) => {
            console.log(err)
            message.error("Не удалось отклонить документ")
        })
}

const declineWitchOutCommentRequest = (
    metaDocumentId: string,
    password: string
) => {
    axios.post('/api/v1/decline', {
        documentId: metaDocumentId,
        password: password
    }, {
        responseType: 'arraybuffer',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
            'Accept': 'application/pdf'
        }
    })
        .then(() => {
            message.success("Документ отклонен")
        })
        .catch((err) => {
            console.log(err)
            message.error("Не удалось отклонить документ")
        })
}

const downloadDocument = (fileId: string) => {
    axios.get(`/api/v1/getpdf/${fileId}`, {
        responseType: 'blob',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    })
        .then((response) => {
            window.open(URL.createObjectURL(response.data))
        })
        .catch((err) => {
            console.log(err)
            message.error("Не удалось открыть документ")
        })
}

export {
    signRequest,
    declineRequest,
    downloadDocument
}